<template>
  <div class="app-container">
    <el-row>
      <el-col :span="2">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="openAddMaterielCategory"
          >{{ $t("base.materiel.category.create") }}</el-button
        >
      </el-col>
      <el-col :span="6" :offset="16">
        <div class="filter-container" align="right">
          <el-input
            placeholder="请输入物料名称"
            maxlength="50"
            clearable
            v-model="materielCategory.listQuery.searchPhrase"
            size="small"
            style="width: 200px"
            class="filter-item"
            @keyup.enter.native="searchCondition"
          />
          <el-button
            size="small"
            class="filter-item"
            type="primary"
            :loading="materielCategory.listLoading"
            icon="el-icon-search"
            @click="searchCondition"
          >
            {{ $t("commons.search") }}
          </el-button>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-table
        v-loading="materielCategory.listLoading"
        :data="materielCategory.list"
        element-loading-text="loading"
        border
        fit
        highlight-current-row
        stripe
      >
        <el-table-column :label="$t('registry.name')">
          <template slot-scope="scope">
            <span>{{ scope.row.materielCategoryName }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('base.materiel.code')">
          <template slot-scope="scope">
            <span>{{ scope.row.materielCategoryCode }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('base.materiel.desc')">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('commons.lastUpdateDate')">
          <template slot-scope="scope">
            <i class="el-icon-time" />
            <span v-if="scope.row.lastUpdateDate != null">
              {{
                scope.row.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss")
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100"
          :label="$t('commons.actions')"
        >
          <template slot-scope="scope">
            <el-tooltip
              :content="$t('commons.edit')"
              placement="bottom"
              :open-delay="openDelay"
            >
              <el-button
                size="mini"
                icon="el-icon-edit"
                type="primary"
                circle
                :disabled="scope.row.processing"
                @click="openUpdateMaterielCategory(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('commons.delete')"
              placement="bottom"
              :open-delay="openDelay"
            >
              <el-button
                size="mini"
                icon="el-icon-delete"
                type="danger"
                circle
                :disabled="scope.row.processing"
                @click="deleteMaterielCategory(scope.row)"
              >
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="materielCategory.total > 0"
        :total="materielCategory.total"
        :page.sync="materielCategory.listQuery.current"
        :limit.sync="materielCategory.listQuery.rowCount"
        @pagination="getMaterielCategoryPage"
      />
    </el-row>

    <!-- 编辑物料分类对话框 -->
    <el-dialog
      :visible.sync="materielCategory.addDialogVisible"
      :before-close="cancelMaterielCategorySave"
      width="30%"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <title-icon />{{ materielCategory.title }}
      </template>
      <el-form
        label-position="top"
        :model="materielCategory.form"
        ref="materielCategoryForm"
        :rules="materielCategory.formRules"
      >
        <el-input
          type="hidden"
          class="hidden"
          v-model="materielCategory.form.materielCategoryId"
        ></el-input>
        <el-row>
          <el-col :span="11">
            <el-form-item
              :label="$t('registry.name')"
              prop="materielCategoryName"
            >
              <el-input
                v-model="materielCategory.form.materielCategoryName"
                maxlength="50"
                :show-word-limit="true"
                @input="setCode()"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item
              :label="$t('base.materiel.code')"
              prop="materielCategoryCode"
            >
              <el-input
                v-model="materielCategory.form.materielCategoryCode"
                maxlength="50"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('base.materiel.desc')" prop="description">
              <el-input
                type="textarea"
                :rows="3"
                v-model="materielCategory.form.description"
                maxlength="160"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelMaterielCategorySave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="handleMaterielCategorySave"
          v-loading.fullscreen.lock="materielCategory.createLoading"
          >{{ $t("commons.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterielCategoryPage,
  getMaterielCategoryById,
  createMaterielCategory,
  updateMaterielCategory,
  deleteMaterielCategory,
} from "@/api/business/base/tenant/materielcategory";
import Pagination from "@/components/Pagination";
import pinyin from "js-pinyin";

export default {
  name: "MaterielCategoryManager",
  components: {
    Pagination,
  },
  data() {
    return {
      openDelay: 1000,
      materielCategory: {
        title: null,
        addDialogVisible: false,
        list: [],
        listLoading: false,
        total: 0,
        listQuery: {
          current: 1,
          rowCount: 10,
          searchPhrase: null,
        },
        form: {
          materielCategoryId: null,
          materielCategoryName: null,
          materielCategoryCode: null,
          description: null,
        },
        formRules: {
          materielCategoryName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          materielCategoryCode: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  created() {
    this.searchCondition();
  },
  methods: {
    setCode() {
      let name = this.materielCategory.form.materielCategoryName;
      this.materielCategory.form.materielCategoryCode =
        pinyin.getCamelChars(name);
    },
    searchCondition() {
      this.materielCategory.listQuery.current = 1;
      this.getMaterielCategoryPage();
    },
    getMaterielCategoryPage() {
      this.materielCategory.listLoading = true;
      getMaterielCategoryPage(this.materielCategory.listQuery).then(
        (response) => {
          this.materielCategory.total = response.total;
          this.materielCategory.list = response.rows;
          this.materielCategory.listLoading = false;
        }
      );
    },
    deleteMaterielCategory(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteMaterielCategory({
            materielCategoryId: row.materielCategoryId,
            materielCategoryName: row.materielCategoryName,
          })
            .then(() => {
              //成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              //删除成功后重新渲染table
              this.searchCondition();
            })
            .catch((error) => {
              console.log(`删除失败，原因 => ${error}`);
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    openAddMaterielCategory() {
      this.clearMaterielCategoryForm();
      this.materielCategory.title = this.$t("base.materiel.category.create");
      this.materielCategory.addDialogVisible = true;
    },
    openUpdateMaterielCategory(row) {
      getMaterielCategoryById({
        materielCategoryId: row.materielCategoryId,
      }).then((res) => {
        this.materielCategory.title = this.$t("base.materiel.category.edit");
        this.materielCategory.form = res;
        this.materielCategory.addDialogVisible = true;
      });
    },
    cancelMaterielCategorySave() {
      this.materielCategory.addDialogVisible = false;
    },
    handleMaterielCategorySave() {
      this.$refs.materielCategoryForm.validate((v) => {
        if (v) {
          if (this.materielCategory.form.materielCategoryId == null) {
            createMaterielCategory(this.materielCategory.form)
              .then(() => {
                this.materielCategory.addDialogVisible = false;
                //成功提示
                this.$message({
                  message: this.$t("message.operationSuccess"),
                  type: "success",
                });
                this.getMaterielCategoryPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            updateMaterielCategory(this.materielCategory.form)
              .then(() => {
                this.materielCategory.addDialogVisible = false;
                //成功提示
                this.$message({
                  message: this.$t("message.operationSuccess"),
                  type: "success",
                });
                this.getMaterielCategoryPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    clearMaterielCategoryForm() {
      this.materielCategory.form = {
        materielCategoryId: null,
        materielCategoryName: null,
        materielCategoryCode: null,
        description: null,
        tagList: [],
      };
      if (this.$refs.materielCategoryForm) {
        this.$refs.materielCategoryForm.resetFields();
      }
    },
  },
};
</script>
