import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 物料分类查询
 */
export function getMaterielCategoryPage(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materielcategory/page',
        method: 'get',
        params
    })
}

/**
 * 物料列表查询
 */
export function getMaterielCategoryList(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materielcategory/list',
        method: 'get',
        params
    })
}

/**
 * 物料分类信息
 * @param params
 */
export function getMaterielCategoryById(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materielcategory/find/'+params.materielCategoryId,
        method: 'get',
    })
}

/**
 * 创建物料分类
 * @param params
 */
export function  createMaterielCategory(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materielcategory/create',
        method: 'post',
        data : params
    })
}

/**
 * 更新物料分类
 * @param params
 */
export function  updateMaterielCategory(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materielcategory/update',
        method: 'post',
        data : params
    })
}

/**
 * 删除物料分类
 * @param params
 */
export function  deleteMaterielCategory(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materielcategory/delete',
        method: 'delete',
        data : params
    })
}

/**
 * 查询物料分类标签
 * @param params
 */
export function findMaterielCategoryLabel(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
        method: 'get',
    })
}

